import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';

// This FF is due for cleanup as folders has been rolled out to 100%
// New changes do not need to be wrapped under this feature gate/experiment
export const getIsFolderEnabled = () =>
	fg('confluence_cloud_folders') ||
	expValEquals<'test' | 'control'>('confluence_cloud_folders_experiment', 'cohort', 'test');

export const useIsFolderEnabled = (): {
	isFolderEnabled: boolean;
} => {
	const isFolderEnabled = getIsFolderEnabled();
	return { isFolderEnabled };
};
